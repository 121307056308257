import React from 'react'

import {heroInsightPropTypes} from 'helpers/propTypes'
import BackButton from 'components/UI/BackButton'
import Media from 'components/UI/Media'
import Section from 'components/UI/Section'
import Title from 'components/UI/Title'

import useStyles from './style'

const Hero = ({
  author,
  image,
  title,
  type,
  backButtonLabel,
  backButtonHasShortLink,
  isPreview,
  navLocale,
  countryCode,
}) => {
  const classes = useStyles()
  const backLink = backButtonHasShortLink
    ? `../${countryCode}/${navLocale}/insights`
    : '../../insights'

  return (
    <Section hasPaddingSide hasPaddingBottom={false}>
      <BackButton
        backLink={backLink}
        backLabel={backButtonLabel}
        positionAbsolute
      />
      <div className={classes.heroPadding}>
        <div className={classes.container}>
          <Title type="mainTitle" hat={type.name}>
            {title}
          </Title>
          <Title
            variant="h2"
            color="gray"
            type="smallTitle"
            fontFamily="medium"
          >
            {author}
          </Title>
          {isPreview && (
            <div
              className={classes.previewImage}
              style={{backgroundImage: `url(${image})`}}
            />
          )}
          {image && !isPreview ? (
            <div className={classes.heroImage}>
              <Media data={image} is169Ratio />
            </div>
          ) : null}
        </div>
      </div>
    </Section>
  )
}

Hero.propTypes = heroInsightPropTypes.isRequired

export default Hero
