import {makeStyles} from '@mui/styles'

const useStyles = makeStyles(({spacing}) => ({
  insightCta: {
    padding: spacing(1),
  },
  bottom: {
    paddingBottom: spacing(20),
  },
}))

export default useStyles
