/* eslint-disable no-undef */
import {string} from 'prop-types'
import React, {useEffect} from 'react'

import {addExternalScript} from 'helpers/utils'

import useStyles from './styles'

const HubspotEmbedForm = ({formId}) => {
  const classes = useStyles()

  const initEmbeddedForm = async () =>
    hbspt &&
    hbspt.forms.create({
      region: 'eu1',
      portalId: '25509106',
      formId,
      target: '#hubspotPopupForm',
    })

  useEffect(() => {
    addExternalScript('https://js-eu1.hsforms.net/forms/v2.js', () =>
      initEmbeddedForm(),
    )
  }, [])

  return (
    <div className={classes.container}>
      <div id="hubspotPopupForm" />
    </div>
  )
}

export default HubspotEmbedForm

HubspotEmbedForm.propTypes = {
  formId: string,
}
HubspotEmbedForm.defaultProps = {
  formId: '',
}
