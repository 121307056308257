import {makeStyles} from '@mui/styles'

const useStyles = makeStyles(({palette, fontSizes, breakpoints, spacing}) => ({
  heroPadding: {
    display: 'flex',
    justifyContent: 'center',
    // paddingBottom: spacing(3),
    [breakpoints.down('sm')]: {
      paddingBottom: 0,
    },
  },
  container: {
    width: '85%',
    [breakpoints.down('sm')]: {
      width: '100%',
    },
    '& img': {
      borderRadius: spacing(1, 5),
    },
  },
  heroImage: {
    margin: spacing(5, 0),
  },
  type: {
    color: palette.primary.main,
    fontFamily: 'CamptonSemiBold',
    fontSize: fontSizes.description,
  },
  backLink: {
    top: '-15%',
    left: 0,
    color: palette.primary.main,
    [breakpoints.down('sm')]: {
      visibility: 'hidden',
    },
  },
  previewImage: {
    margin: spacing(5, 0),
    maxHeight: '35vh',
    borderRadius: spacing(1, 5),
    height: '250px',
    background: ' transparent no-repeat center',
    backgroundSize: 'cover',
  },
  icons: {
    paddingTop: spacing(2),
  },
  author: {
    color: palette.text.grey,
  },
}))

export default useStyles
