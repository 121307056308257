/* eslint-disable no-use-before-define */
import {bool, func, node, string} from 'prop-types'
import React, {createRef, useCallback, useEffect, useState} from 'react'
import useClickOutside from 'hooks/useClickOutside'

import Title from 'components/UI/Title'
import useIsMobile from 'hooks/useIsMobile'

import useStyles from './styles'

const FormPopup = ({children, formPopupTitle, setShowPopper, showPopper}) => {
  const classes = useStyles()
  const rootRef = createRef()
  const isMobile = useIsMobile()

  useClickOutside(rootRef, () => setShowPopper(false))

  const [alreadyDisplayedLightBox, setAlreadyDisplayedLightBox] =
    useState(false)

  const displayContactLightBox = () => {
    setShowPopper(!showPopper)
    setAlreadyDisplayedLightBox(true)
    window.removeEventListener('scroll', onBottomOfPageReached)
  }

  const onBottomOfPageReached = useCallback(() => {
    const footerPos = document
      .getElementById('page-footer')
      .getBoundingClientRect().top

    if (footerPos - window.innerHeight < 200) {
      if (!showPopper) {
        displayContactLightBox()
      }
    }
  }, [showPopper])

  useEffect(() => {
    if (!alreadyDisplayedLightBox) {
      window.addEventListener('scroll', onBottomOfPageReached)
    }

    return () => {
      window.removeEventListener('scroll', onBottomOfPageReached)
    }
  }, [onBottomOfPageReached])

  const wrapperDisplayCondition = showPopper ? 'flex' : 'none'

  return (
    <>
      <div
        style={{
          display: wrapperDisplayCondition,
        }}
        className={classes.wrapper}
      >
        <div ref={rootRef} id="FormPopup" className={classes.container}>
          <button
            type="button"
            onClick={() => setShowPopper(false)}
            className={classes.closeButton}
          >
            <span className={classes.closeButtonIcon}>x</span>
          </button>
          <Title type={isMobile && 'mediumTitle'} variant="h3">
            {formPopupTitle}
          </Title>
          {children}
        </div>
      </div>
    </>
  )
}

FormPopup.propTypes = {
  children: node,
  formPopupTitle: string,
  setShowPopper: func,
  showPopper: bool,
}
FormPopup.defaultProps = {
  children: null,
  formPopupTitle: '',
  setShowPopper: () => {},
  showPopper: false,
}

export default FormPopup
