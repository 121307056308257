import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import RoundButton from 'components/UI/RoundButton'
import useStyles from './style'

const InsightCta = ({
  calToAction,
  callToActionIsForm,
  externalLink,
  setshowCallToActionForm,
  ctaPosition,
}) => {
  const classes = useStyles()

  return (
    <div
      className={classNames(classes.insightCta, {
        [classes.bottom]: ctaPosition === 'Bottom',
      })}
    >
      {calToAction && !callToActionIsForm && (
        <RoundButton
          color="secondary"
          arrow
          isCentered
          href={externalLink}
          externalLink
          isNowrap
        >
          {calToAction}
        </RoundButton>
      )}
      {calToAction && callToActionIsForm && (
        <RoundButton
          color="secondary"
          arrow
          isCentered
          action={setshowCallToActionForm}
          isNowrap
          isAction
          isFilled
        >
          {calToAction}
        </RoundButton>
      )}
    </div>
  )
}

InsightCta.defaultProps = {
  callToActionIsForm: false,
  ctaPosition: 'Top',
  externalLink: '',
}

InsightCta.propTypes = {
  callToActionIsForm: PropTypes.bool,
  calToAction: PropTypes.string.isRequired,
  ctaPosition: PropTypes.string,
  externalLink: PropTypes.string,
  setshowCallToActionForm: PropTypes.func.isRequired,
}

export default InsightCta
