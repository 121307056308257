import * as R from 'ramda'
import {graphql} from 'gatsby'
import {shape} from 'prop-types'
import loadable from '@loadable/component'
import React, {useState} from 'react'

import {
  allContentfulInsightPropTypes,
  allContentfulInsightsPropTypes,
  locationPropTypes,
  pageContextPropTypes,
} from 'helpers/propTypes'
import {formRedirection, socialShare} from 'helpers/utils'
import Article from 'components/Insight/Article'
import Cta from 'components/Insight/Cta'
import FormPopup from 'components/UI/FormPopup'
import Hero from 'components/Insight/Hero'
import HubspotEmbedForm from 'components/UI/HubspotEmbedForm'
import SEO from 'components/seo'
import SwitchForm from 'components/UI/SwitchForm'
// import SwitchNewsLetterForm from 'components/UI/SwitchNewsLetterForm'
import useIsBrowser from 'hooks/useIsBrowser'
import useIsMobile from 'hooks/useIsMobile'

const Insight = ({pageContext, data, location}) => {
  const [showCallToActionForm, setshowCallToActionForm] = useState(false)
  const Related = loadable(() => import('components/UI/Related'))
  const SocialShare = loadable(() => import('components/UI/SocialShare'))

  const isMobile = useIsMobile()
  const {hasShortPath, prefix} = pageContext
  const {countryCode, navLocale, salesforce, newsletterCampaignToken} = R.path(
    ['contentfulCountry'],
    data,
  )
  const countryData = R.path(['contentfulCountry'], data)

  const relatedPrefix = hasShortPath
    ? `../${countryCode}/${navLocale}/insights/`
    : '../'

  const {
    insightBackButtonLabel,
    insightOtherArticlesLabel,
    insightShareButtons,
    newsletterCallToAction,
    newsletterTitle,
    newsletterDescription,
    newsletterPlaceholder,
    newsletterImage: {file},
    newsletterGdprConsent,
  } = R.pathOr(null, ['allContentfulInsights', 'nodes', 0], data)

  const {
    ctaPosition,
    author,
    slug,
    content,
    title,
    image,
    metaTitle,
    metaDescription,
    type,
    industries,
    calToAction,
    externalLink,
    services2,
    callToActionIsForm,
    callToActionFormId,
    formPopupTitle,
  } = R.pathOr(null, ['allContentfulInsight', 'nodes', 0], data)

  const relatedInsight = R.pathOr(null, ['relatedInsight', 'edges'], data)

  const siteURL = R.pathOr(null, ['site', 'siteMetadata', 'siteUrl'], data)

  const newsletterFormRedirect = formRedirection(
    siteURL,
    countryCode,
    navLocale,
    'newsletter',
  )

  const socialShareRedirect = socialShare(
    siteURL,
    countryCode,
    navLocale,
    prefix,
    slug,
    hasShortPath,
  )

  const pageMetaData = {
    article: true,
    author,
    image: image && R.pathOr(' ', ['gatsbyImageData'], image),
    metaTitle: R.pathOr('', ['metaTitle'], metaTitle) || metaTitle,
    metaDescription: R.pathOr('', ['metaDescription'], metaDescription),
    siteMetaData: R.pathOr('', ['siteMetaData'], data),
    genericData: R.pathOr('', ['contentfulGenericData'], data),
  }

  const articleIndustries = industries && R.map(el => el.name, industries)

  const showFormPopupCondition = callToActionIsForm && showCallToActionForm

  const formData = R.pathOr(null, ['contentfulForm'], data)

  const contactFormId = 'e5689265-be7e-46bd-805b-21947250898f'

  const formPopupTitleIsEmpty =
    formPopupTitle === null || R.isEmpty(formPopupTitle)

  const formCondition =
    callToActionFormId !== null &&
    !R.isEmpty(callToActionFormId) &&
    callToActionFormId !== contactFormId

  const formRedirect = formRedirection(siteURL, countryCode, navLocale, 'lead')

  const richTextDocuments = R.pathOr(null, ['richTextDocuments', 'nodes'], data)

  return (
    <>
      <SEO
        pageContext={pageContext}
        countryData={countryData}
        pageMetaData={pageMetaData}
      />
      {image && (image.gatsbyImageData || image.file) && title && type && (
        <Hero
          author={author}
          image={image}
          title={title}
          type={type}
          backButtonLabel={insightBackButtonLabel}
          backButtonHasShortLink={hasShortPath}
          navLocale={navLocale}
          countryCode={countryCode}
        />
      )}
      {(ctaPosition === 'Top' || ctaPosition === null) && (
        <Cta
          calToAction={calToAction}
          callToActionIsForm={callToActionIsForm}
          externalLink={externalLink}
          setshowCallToActionForm={setshowCallToActionForm}
          ctaPosition={ctaPosition}
        />
      )}
      <Article
        content={content}
        industries={articleIndustries}
        type={type}
        services={services2}
        richTextDocuments={richTextDocuments}
      />
      {ctaPosition === 'Bottom' && (
        <Cta
          calToAction={calToAction}
          callToActionIsForm={callToActionIsForm}
          externalLink={externalLink}
          setshowCallToActionForm={setshowCallToActionForm}
          ctaPosition={ctaPosition}
        />
      )}
      <SocialShare
        title={title}
        location={useIsBrowser && location}
        siteURL={siteURL}
        socialMediaLinks={insightShareButtons}
        isVertical={!isMobile}
        redirect={socialShareRedirect}
      />
      {/* <SwitchNewsLetterForm
        campaignToken={newsletterCampaignToken}
        hasLightGreyBackground
        hubSpotCountry={salesforce}
        isArticle
        locale={navLocale}
        location={location}
        newsLetterCallToAction={newsletterCallToAction}
        newsLetterDescription={newsletterDescription}
        newsLetterGdprConsent={newsletterGdprConsent}
        newsLetterImage={file}
        newsLetterPlaceholder={newsletterPlaceholder}
        newsLetterTitle={newsletterTitle}
        redirect={newsletterFormRedirect}
      /> */}
      {relatedInsight && (
        <Related
          cards={relatedInsight}
          title={insightOtherArticlesLabel}
          cardSize="wide"
          buttonsPosition="bottom"
          prefix={relatedPrefix}
        />
      )}
      {callToActionIsForm && (
        <FormPopup
          showPopper={showFormPopupCondition}
          setShowPopper={setshowCallToActionForm}
          formPopupTitle={!formPopupTitleIsEmpty && formPopupTitle}
        >
          {formCondition ? (
            <HubspotEmbedForm formId={callToActionFormId} />
          ) : (
            <SwitchForm
              data={formData}
              location={location}
              locale={navLocale}
              redirection={formRedirect}
              redirect={formRedirect}
              websiteSource="insight"
              isContact
              hasNoTitle={!formPopupTitleIsEmpty}
              hubSpotCountry={salesforce}
              isPopup
            />
          )}
        </FormPopup>
      )}
    </>
  )
}

Insight.propTypes = {
  data: shape({
    allContentfulInsight: allContentfulInsightPropTypes,
    allContentfulInsights: allContentfulInsightsPropTypes,
  }).isRequired,
  location: locationPropTypes.isRequired,
  pageContext: pageContextPropTypes.isRequired,
}

Insight.defaultProps = {}

export default Insight

export const pageQuery = graphql`
  query templateInsight(
    $nodeLocale: String
    $contentId: String
    $technicalName: String
  ) {
    allContentfulInsight(
      filter: {node_locale: {eq: $nodeLocale}, contentful_id: {eq: $contentId}}
    ) {
      nodes {
        ctaPosition
        author
        slug
        title
        node_locale
        contentful_id
        publicationDate(formatString: "DD.MM.YY")
        image {
          file {
            url
            contentType
          }
          gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
        }
        metaTitle {
          metaTitle
        }
        metaDescription {
          metaDescription
        }
        type {
          name
        }
        content {
          __typename
          raw
          references {
            ... on ContentfulAsset {
              description
              gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
              contentful_id
              file {
                url
                contentType
              }
              __typename
            }
            ... on ContentfulEntry {
              contentful_id
              __typename
              ... on ContentfulCallToAction {
                contentful_id
                __typename
                callToActionText
                callToActionLink
              }
            }
          }
        }
        industries {
          name {
            name
          }
        }
        calToAction
        callToActionIsForm
        callToActionFormId
        formPopupTitle
        externalLink
        services2 {
          name
        }
      }
    }
    relatedInsight: allContentfulInsight(
      filter: {
        country3: {elemMatch: {technicalName: {eq: $technicalName}}}
        node_locale: {eq: $nodeLocale}
        contentful_id: {ne: $contentId}
      }
      limit: 10
      sort: {publicationDate: DESC}
    ) {
      edges {
        node {
          ...insightFields
          metaDescription {
            metaDescription
          }
          image {
            gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
            description
          }
        }
      }
    }
    allContentfulInsights(filter: {node_locale: {eq: $nodeLocale}}) {
      nodes {
        insightBackButtonLabel
        insightOtherArticlesLabel
        insightShareButtons {
          icon {
            file {
              url
            }
          }
        }
        node_locale
        newsletterCallToAction
        newsletterDescription
        newsletterPlaceholder
        newsletterTitle
        newsletterImage {
          file {
            url
            fileName
          }
        }
        newsletterGdprConsent {
          raw
          references {
            ... on ContentfulSimplePage {
              contentful_id
              slug
              name
            }
          }
        }
      }
    }
    contentfulCountry(
      node_locale: {eq: $nodeLocale}
      technicalName: {eq: $technicalName}
    ) {
      id
      name
      newsletterCampaignToken
      salesforce
      ...countryDataFields
    }
    allContentfulService(filter: {node_locale: {eq: $nodeLocale}}) {
      edges {
        node {
          ...serviceFields
        }
      }
    }
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    siteMetaData: site {
      ...SiteMetadata
    }
    contentfulGenericData {
      genericKeywords {
        content
      }
    }
    contentfulForm(name: {eq: "Lead form"}, node_locale: {eq: $nodeLocale}) {
      ...FormFields
    }
    richTextDocuments: allContentfulDocument(
      filter: {node_locale: {eq: "en-US"}}
    ) {
      nodes {
        contentful_id
        name
      }
    }
  }
`
