import {makeStyles} from '@mui/styles'

const useStyles = makeStyles(({breakpoints}) => ({
  container: {
    width: 500,
    position: 'relative',
    margin: 'auto',
    [breakpoints.down('md')]: {
      width: '100%',
      maxHeight: 500,
      height: '100%',
    },
  },
}))

export default useStyles
